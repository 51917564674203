import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { Route, Switch } from 'react-router-dom';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { SettingsAuthenticationContainer } from './settings_authentication_page';
import { SettingsNotificationContainer } from './settings_notification_page';
import { SettingsDeviceContainer } from './settings_device_page';
import { GoogleCalendarSyncContainer } from '../../containers/SettingsPage/settings_google_calendar_page';
import { SettingsDesktopCallContainer } from './settings_desktop_call_page';
import { isMobileDevice, isIOS } from '../../utilities/is_mobile';
import { themePalette, themeLinks } from '../../utilities/branding';
import {
	notificationCategories,
	NotificationSummary,
} from '../../components/notifications/notificationSummary';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { jwt_auth } from '../../utilities/auth';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { P } from '../../utilities/auth/permissions';
import { SettingsClickToCallCallerIdContainer } from './settings_click_to_call_caller_id_page';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import SecurityIcon from '@material-ui/icons/Security';
import HelpIcon from '@material-ui/icons/Help';
import SyncIcon from '@material-ui/icons/Sync';
import { useNavigateTo } from '../../utilities/hooks';
import { Strings } from '../../assets/common/strings';


interface Props extends NavigationProps { }
const SettingsPage: React.FC<Props> = (props) => {

	const goToPushNotifications = useNavigateTo(navRoutes.settingsNotifications.path);
	const goToAuthentication = useNavigateTo(navRoutes.settingsAuthentication.path);
	const goToSettingsDesktop = useNavigateTo(navRoutes.settingsDesktopCall.path);
	const goToAppSupport = useNavigateTo(navRoutes.settingsAppSupport.path);
	const goToGoogleCalendar = useNavigateTo(navRoutes.googleCalendarSync.path);
	const goToEmailTemplates = useNavigateTo(navRoutes.templateManagement.path);
	const goToEmailAutomation = useNavigateTo(navRoutes.emailAutomation.path);
	const goToSMSTemplates = useNavigateTo(navRoutes.smsTemplateManagement.path);
	const goToClickToCallSettings = useNavigateTo(navRoutes.settingsClickToCallCallerId.path);
	const isVanillaSoftExperience = jwt_auth.isFeatureFlagExists(Strings.FeatureFlag.vanillasoftexperience);

	const renderSettingsMainPage = () => (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Settings"
					rightButtons={
						<MoreMenu
							children={[createHelpAction(themeLinks.helpLinkSettings)]}
						/>
					}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.settings}/>

			<DesktopPadding>

				<ToggleCard
					subtitle={'My Settings'}
					alwaysExpanded
					noPadding
				>
					{!isBrokerage && <SimpleListItem
						title={'Push Notifications'}
						subtitle={'Modify notification settings'}
						icon={<NotificationsActiveIcon/>}
						onClick={goToPushNotifications}
					/>}
					{/* <SimpleListItem
						title={`${isIOS ? 'TouchId' : 'Fingerprint'} & Pin`}
						subtitle={'Update authentication preferences'}
						icon={<SecurityIcon/>}
						onClick={goToAuthentication}
					/> */}
					{!isMobileDevice && !isVanillaSoftExperience && <SimpleListItem
						title={'Phone and Text'}
						subtitle={'Update PC/Laptop phone and text preferences'}
						icon={<PhoneIcon/>}
						onClick={goToSettingsDesktop}
					/>}
					<SimpleListItem
						title={'App Support'}
						subtitle={'Device Information and Help Contact'}
						icon={<HelpIcon/>}
						onClick={goToAppSupport}
					/>
				</ToggleCard>

				{((!isBrokerage || !isMobileDevice) && !isVanillaSoftExperience) && <ToggleCard
					subtitle={'System Settings'}
					alwaysExpanded
					noPadding
				>
					{!isBrokerage && (
						<SimpleListItem
							title={'Google Calendar Sync'}
							subtitle={'Sync your appointments, calls, and tasks with Google Calendar'}
							icon={<SyncIcon/>}
							onClick={goToGoogleCalendar}
						/>
					)}
					{jwt_auth.hasPermission(P.BulkEmail) && !isMobileDevice && (
						<SimpleListItem
							title={'Manage Email Templates'}
							subtitle={'Create, Edit, and Delete Email Templates'}
							icon={<EmailIcon/>}
							onClick={goToEmailTemplates}
						/>
					)}
					{jwt_auth.hasPermission(P.AutomatedEmail) && !isMobileDevice && (
						<SimpleListItem
							title={'Automated Email Configuration'}
							subtitle={'Customize Triggered Email Events'}
							icon={<EmailIcon/>}
							onClick={goToEmailAutomation}
						/>
					)}
					{(jwt_auth.hasPermission(P.CorporateSmsCreate) || jwt_auth.hasPermission(P.SmsTemplateCreate)) && !isMobileDevice && (
						<SimpleListItem
							title={'Manage SMS Templates'}
							subtitle={'Create, Edit, and Delete SMS Templates'}
							icon={<SmsIcon/>}
							onClick={goToSMSTemplates}
						/>
					)}
					{jwt_auth.hasPermission(P.ClickToCall) && !isMobileDevice && (
						<SimpleListItem
							title={'Manage Click to Call Caller ID'}
							subtitle={'Setup/Update your Caller ID'}
							icon={<PhoneIcon/>}
							onClick={goToClickToCallSettings}
						/>
					)}
				</ToggleCard>}
			</DesktopPadding>
		</BasePageContainer>
	);

	return (
		<Switch>
			<Route
				exact
				path={`${props.match.url}`}
				render={props => renderSettingsMainPage()}
			/>
			<Route
				exact
				path={navRoutes.settingsNotifications.path}
				render={props => <SettingsNotificationContainer />}
			/>
			<Route
				exact
				path={navRoutes.settingsAuthentication.path}
				render={props => <SettingsAuthenticationContainer />}
			/>
			<Route
				exact
				path={navRoutes.settingsAppSupport.path}
				render={props => <SettingsDeviceContainer />}
			/>
			<Route
				exact
				path={navRoutes.googleCalendarSync.path}
				render={props => <GoogleCalendarSyncContainer />}
			/>
			<Route
				exact
				path={navRoutes.settingsDesktopCall.path}
				render={props => <SettingsDesktopCallContainer />}
			/>
			<Route
				exact
				path={navRoutes.settingsClickToCallCallerId.path}
				render={() => <SettingsClickToCallCallerIdContainer />}
			/>
		</Switch>
	);
};

const mapStateToProps = (state: AppState): Partial<Props> => ({});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
	navigateBack: () => dispatch(navigateBack()),
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
});

export const SettingsContainer = connect(mapStateToProps, mapDispatchToProps, true)(
	SettingsPage
);
